<template>
  <v-card class="auto-height-card">
    <v-tabs v-model="selectedTab" background-color="primary">
      <v-tab v-for="(tab, index) in tabs" :key="index">{{ tab }}</v-tab>
    </v-tabs>
    <div class="overall-layout">
      <v-tabs-items v-model="selectedTab" class="scrollable-tabs-items">
        <v-tab-item
          v-for="(tab, index) in tabs"
          :key="index"
          class="scrollable-tab-item"
        >
          <v-container class="bg-surface-variant">
            <v-row no-gutters>
              <v-col cols="1">
                <v-btn @click="incrementGraphdata">graph</v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                v-for="(graphComponent, graphIndex) in getGraphComponentsForTab(
                  index
                )"
                :key="graphIndex"
                cols="6"
                class="custom-col"
              >
                <v-sheet class="pa-2 ma-2 custom-sheet">
                  <component
                    :is="graphComponent"
                    :data="getDataForTab(index, graphIndex)"
                    :plugins="chartPlugins"
                    @resize="handleResize"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import LineChartGraph from "@/components/graphcomponents/LineChartGraph.vue";
import PieChartGraph from "@/components/graphcomponents/PieChartGraph.vue";
import BarChartGraph from "@/components/graphcomponents/BarChartGraph.vue";
import RadarChartGraph from "@/components/graphcomponents/RadarChartGraph.vue";
import ScatterGraph from "@/components/graphcomponents/ScatterGraph.vue";
import PolarAreaGraph from "@/components/graphcomponents/PolarAreaGraph.vue";
import { ref } from "vue";

export default {
  components: {
    BarChartGraph,
    LineChartGraph,
    PieChartGraph,
    RadarChartGraph,
    ScatterGraph,
    PolarAreaGraph,
  },
  data() {
    return {
      chartPlugins: [], // 또는 사용할 플러그인을 정의합니다.
      selectedTab: 0,
      tabs: [
        "Data One",
        "Data Two",
        "Data Three",
        "Data Four",
        "Data Five",
        "Data Six",
        "Data Seven",
        "Data Eight",
      ],
      graphComponents: [
        [
          LineChartGraph,
          PieChartGraph,
          BarChartGraph,
          RadarChartGraph,
          ScatterGraph,
          PolarAreaGraph,
        ],
        [
          BarChartGraph,
          RadarChartGraph,
          ScatterGraph,
          PolarAreaGraph,
          LineChartGraph,
          PieChartGraph,
        ],
        [
          ScatterGraph,
          PolarAreaGraph,
          LineChartGraph,
          PieChartGraph,
          BarChartGraph,
          RadarChartGraph,
        ],
        [
          RadarChartGraph,
          ScatterGraph,
          PolarAreaGraph,
          LineChartGraph,
          PieChartGraph,
          BarChartGraph,
        ],
        [
          PieChartGraph,
          BarChartGraph,
          RadarChartGraph,
          ScatterGraph,
          PolarAreaGraph,
          LineChartGraph,
        ],
        [
          PolarAreaGraph,
          LineChartGraph,
          PieChartGraph,
          BarChartGraph,
          RadarChartGraph,
          ScatterGraph,
        ],
        [
          LineChartGraph,
          ScatterGraph,
          PieChartGraph,
          RadarChartGraph,
          PolarAreaGraph,
          BarChartGraph,
        ],
        [
          BarChartGraph,
          PolarAreaGraph,
          ScatterGraph,
          PieChartGraph,
          RadarChartGraph,
          LineChartGraph,
        ],
      ],
      // tabData: [], // 데이터를 저장할 배열
      tabData: Array(8)
        .fill()
        .map(() => Array(6).fill({ labels: [], values: [] })),
      fetchedData: [],
      errorFetchingData: false,

      // 데이터 관리
      graphData_two: ref({}),
    };
  },
  methods: {
    async fetchData() {
      console.log("Fetching data...");
      try {
        const response = await axios.get("http://localhost:3000/api/1TABLE");
        console.log("Fetched data:", response.data);
        this.fetchedData = response.data;
        this.processData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        this.errorFetchingData = true;
      }
    },
    processData(data) {
      // 데이터를 탭과 그래프 인덱스에 맞게 분배합니다.
      this.tabData.forEach((tab) => {
        tab.forEach((graphData) => {
          graphData.labels = [];
          graphData.values = [];
        });
      });

      data.forEach((item) => {
        // 각 item을 적절한 탭과 그래프 인덱스에 추가합니다.
        const tabIndex = item.TabIndex; // TabIndex는 각 item이 속하는 탭의 인덱스
        const graphIndex = item.GraphIndex; // GraphIndex는 탭 내에서의 그래프 인덱스

        if (this.tabData[tabIndex] && this.tabData[tabIndex][graphIndex]) {
          this.tabData[tabIndex][graphIndex].labels.push(item.Label);
          this.tabData[tabIndex][graphIndex].values.push(item.Value);
        }
      });
    },
    getDataForTab(tabIndex, graphIndex) {
      // 지정된 탭과 그래프 인덱스에 대한 데이터를 반환합니다.
      return this.tabData[tabIndex][graphIndex] || { labels: [], values: [] };
    },
    getGraphComponentsForTab(tabIndex) {
      // 각 탭에 대한 그래프 컴포넌트들을 반환합니다.
      return this.graphComponents[tabIndex] || [];
    },
    handleResize() {
      // Resize handling logic, if any
    },
    incrementGraphdata() {
      console.log("클릭됨");
      // 데이터를 업데이트
      const barlabel = [];
      const barvalue = [];

      // fetchedData가 배열임을 확인
      if (Array.isArray(this.fetchedData)) {
        this.fetchedData.forEach((item, index) => {
          // 각 항목의 labels와 values를 출력
          console.log(`Item ${index + 1}:`);
          console.log(`Labels: ${item.labels.join(", ")}`);
          console.log(`Values: ${item.values.join(", ")}`);

          item.values.forEach((values) => {
            barlabel.push("");
            barvalue.push(values);
          });
          /*
          barlabel.push("");
          barvalue.push(item.values[0]);
          */
        });
        window.lineChartGrapeinputdata(barlabel, barvalue);
        window.barChartGrapeinputdata(barlabel, barvalue);
      } else {
        console.error("fetchedData는 배열이 아닙니다:", this.fetchedData);
      }
    },
  },
  created() {
    this.fetchData(); // 컴포넌트 생성 시 데이터를 가져옵니다.
  },
};
</script>

<style>
@import "~vuetify/dist/vuetify.min.css";
.overall-layout {
  height: 100%;
}
.fill-height {
  border-radius: 0 !important;
  height: 100px;
  width: 1690px;
  padding: 0%;
  margin: 0%;
}
.v-tabs-items {
  height: 100%;
  width: 100%;
  padding: 0%;
  margin: 0%;
}
.v-card.flat {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.custom-tabs-items {
  background-color: white !important;
}
.custom-sheet {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}
.scrollable-tabs-items {
  height: auto !important;
  overflow-y: auto !important;
}
.scrollable-tab-item {
  height: 100%; /* 탭 아이템이 전체 높이를 차지하도록 설정 */
}
.bg-surface-variant {
  height: 800px;
  overflow-y: auto !important;
}
.custom-col {
  height: 400px; /* 원하는 높이로 설정 */
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
</style>
