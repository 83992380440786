import { UITabbedPanel } from './libs/ui.js';

import { SidebarObject } from './Sidebar.Object.js';
//import { SidebarGeometry } from './Sidebar.Geometry.js';
//import { SidebarMaterial } from './Sidebar.Material.js';
//import { SidebarScript } from './Sidebar.Script.js';
import { SidebarIssue } from './Sidebar.Issue.js';


function SidebarProperties( editor ) {

	const strings = editor.strings;

	const container = new UITabbedPanel();
	container.setId( 'properties' );

	container.addTab( 'SidebarIssue', strings.getKey( 'sidebar/properties/issue' ), new SidebarIssue( editor ) );
	container.addTab( 'objectTab', strings.getKey( 'sidebar/properties/object' ), new SidebarObject( editor ) );
	//container.addTab( 'geometryTab', strings.getKey( 'sidebar/properties/geometry' ), new SidebarGeometry( editor ) );
	//container.addTab( 'materialTab', strings.getKey( 'sidebar/properties/material' ), new SidebarMaterial( editor ) );
	//container.addTab( 'scriptTab', strings.getKey( 'sidebar/properties/script' ), new SidebarScript( editor ) );
	container.select( 'SidebarIssue' );

	return container;

}

export { SidebarProperties };
