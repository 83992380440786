import { render, staticRenderFns } from "./PieChartGraph.vue?vue&type=template&id=15bca85c&scoped=true"
import script from "./PieChartGraph.vue?vue&type=script&lang=js"
export * from "./PieChartGraph.vue?vue&type=script&lang=js"
import style0 from "./PieChartGraph.vue?vue&type=style&index=0&id=15bca85c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15bca85c",
  null
  
)

export default component.exports