var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100vh","overflow":"hidden"},attrs:{"pa-0":"","ma-0":""}},[_c('div',{staticClass:"propertydefault scrollable-card pa-0 ma-0 flex-direction:column;"},[_c('v-row',{staticStyle:{"text-align":"left"},attrs:{"justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":_vm.colMd,"justify":"center"}},[_c('v-card',[_c('div',{staticClass:"data-check-tab"},[_c('CCTVLivestreaming',{key:_vm.streamingTimeKey,on:{"error":_vm.onStreamingError}})],1)]),_c('DataCheckCardFirst',{attrs:{"id":"inst_components","title":"도막측정 작업용 이동식협동로봇","is-coating":_vm.isCoating,"is-polishing":!_vm.isCoating,"tx":_vm.inst_Tx,"ty":_vm.inst_Ty,"rz":_vm.inst_Rz,"height":_vm.inst_Lift,"j1":_vm.inst_joint_1,"j2":_vm.inst_joint_2,"j3":_vm.inst_joint_3,"j4":_vm.inst_joint_4,"j5":_vm.inst_joint_5,"j6":_vm.inst_joint_6,"s_front":_vm.inst_isWarningfront,"s_rear":_vm.inst_isWarningback,"b_sensor":_vm.inst_bumper,"emergency":_vm.inst_emergency_stop,"state":_vm.inst_Tool,"battery":_vm.inst_battery}},[_c('v-card-text',[_c('br'),_c('br'),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{staticClass:"inline-block-emergency",staticStyle:{"font-size":"24px","/* 버튼 텍스트 크기 증가 */\n                  font-weight":"bold"},attrs:{"color":"error","dark":"","large":""}},[_vm._v(" 비상정지 ")])],1),_c('br'),_c('br'),_c('div',{staticStyle:{"text-align":"center","display":"flex","-ms-flex-wrap":"wrap","flex-wrap":"wrap","-webkit-box-pack":"center","-ms-flex-pack":"center","justify-content":"center"}},[_c('v-chip',{class:[
                  'subtitle-chip-dif',
                  _vm.$vuetify.theme.dark ? 'dark-mode' : '',
                ],staticStyle:{"font-size":"12px"},attrs:{"pa-2":""}},[_vm._v(" 도막 측정값 ")]),_c('div',{staticClass:"inline-block bold-chip"},[_c('v-chip',{staticClass:"ml-1",attrs:{"density":"compact","small":"","label":""}},[_vm._v(_vm._s(_vm.num))]),_c('span',{staticClass:"chip-label",staticStyle:{"font-size":"12px"}},[_vm._v("측정번호")])],1),_c('div',{staticClass:"inline-block bold-chip"},[_c('v-chip',{staticClass:"ml-6",attrs:{"density":"compact","small":"","label":""}},[_vm._v(_vm._s(_vm.value))]),_c('span',{staticClass:"chip-label ml-6",staticStyle:{"font-size":"12px"}},[_vm._v("측정값")])],1)],1),_c('v-simple-table',{staticClass:"ml-16 mx-auto centered-table",attrs:{"density":"compact"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("측정번호")]),_c('th',[_vm._v("측정값")])])]),_c('tbody',_vm._l((_vm.desserts),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.calories))])])}),0)])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.colMd,"ml-10":"","justify":"center"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card',{staticClass:"mx-auto",style:({
              border: '2px solid #d74b4b',
              maxWidth: '344px',
              height: '187px',
              backgroundColor: _vm.$vuetify.theme.dark
                ? '#042b4d'
                : '#c9d7e3' /* 다크 모드일 때 배경색 */,
              color: _vm.$vuetify.theme.dark
                ? '#ffffff'
                : '#000000' /* 라이트 모드일 때 텍스트 색상 */,
            })},[_c('v-card-text',[_c('p',{staticClass:"small-text"},[_vm._v(" (안전센서) 상태에 따라 색상구분 표시"),_c('br'),_vm._v(" OFF : 회색, 경고 : 황색, 위험 : 적색 "),_c('br')]),_c('div',{staticClass:"top-padding"},[_c('p',{staticClass:"small-text"},[_vm._v(" (범퍼, 비상정지, 툴)ON/OFF상태에 따라 색상표시"),_c('br')])]),_c('div',{staticClass:"top-padding"},[_c('p',{staticClass:"small-text"},[_vm._v(" (도막측정값) 표와 같이 배열, 측정번호에 해당하는 셀에 값 표시 ")])])])],1),_c('DataCheckCardFirst',{attrs:{"id":"polish_components","title":" 폴리싱 작업용 이동식협동로봇","is-coating":_vm.isCoating,"is-polishing":!_vm.isCoating,"tx":_vm.polish_Tx,"ty":_vm.polish_Ty,"rz":_vm.polish_Rz,"height":_vm.polish_Lift,"j1":_vm.polish_joint_1,"j2":_vm.polish_joint_2,"j3":_vm.polish_joint_3,"j4":_vm.polish_joint_4,"j5":_vm.polish_joint_5,"j6":_vm.polish_joint_6,"s_front":_vm.polish_isWarningfront,"s_rear":_vm.polish_isWarningback,"b_sensor":_vm.polish_bumper,"emergency":_vm.polish_emergency_stop,"state":_vm.polish_Tool,"battery":_vm.polish_battery}},[_c('br'),_c('br'),_c('br'),_c('div',[_c('div',[_c('v-chip',{staticClass:"other-facilities bold-chip-title",staticStyle:{"background-color":"#e3b23c"},attrs:{"label":""}},[_vm._v(" 기타 설비 ")])],1),_c('br'),_c('br'),_c('div',[_c('div',{staticStyle:{"text-align":"center","display":"flex","-ms-flex-wrap":"wrap","flex-wrap":"wrap","-webkit-box-pack":"center","-ms-flex-pack":"center","justify-content":"left","width":"100%"}},[_c('v-chip',{class:[
                      'subtitle-chip bold-chip-label',
                      _vm.$vuetify.theme.dark ? 'dark-mode' : '',
                    ],staticStyle:{"font-size":"12px"},attrs:{"pa-2":"","large":""}},[_c('span',{staticClass:"text-background"},[_vm._v("I/O 상태")])]),_c('div',{staticClass:"inline-block"},[_c('v-chip',{staticClass:"custom-chip",style:({
                        backgroundColor:
                          _vm.turnroll_bumper === '경고'
                            ? '#dab901'
                            : _vm.turnroll_bumper === '위험'
                            ? '#ce6868'
                            : '',
                      }),attrs:{"density":"compact","id":"turnroll_bumper","label":"","small":""}},[_c('div',{staticClass:"chip-content"},[_c('div',{staticClass:"chip-text"},[_vm._v(" "+_vm._s(_vm.turnroll_bumper === "경고" ? "경고" : "위험")+" ")]),_c('div',{staticClass:"label-divider"}),_c('span',{staticClass:"bold-chip",staticStyle:{"font-size":"12px"}},[_vm._v("범퍼센서")])])])],1),_c('div',{staticClass:"inline-block"},[_c('v-chip',{staticClass:"custom-chip",style:({
                        backgroundColor:
                          _vm.turnroll_emergency_stop === '경고'
                            ? '#dab901'
                            : _vm.turnroll_emergency_stop === '위험'
                            ? '#ce6868'
                            : '',
                      }),attrs:{"density":"compact","id":"turnroll_emergency_stop","label":"","small":""}},[_c('div',{staticClass:"chip-content"},[_c('div',{staticClass:"chip-text"},[_vm._v(" "+_vm._s(_vm.turnroll_emergency_stop === "경고" ? "경고" : "위험")+" ")]),_c('div',{staticClass:"label-divider"}),_c('span',{staticClass:"bold-chip",staticStyle:{"font-size":"12px"}},[_vm._v("비상정지")])])])],1),_c('div',{staticClass:"inline-block"},[_c('v-chip',{staticClass:"custom-chip",style:({
                        backgroundColor:
                          _vm.turnroll_safety_censor_front === '경고'
                            ? '#dab901'
                            : _vm.turnroll_safety_censor_front === '위험'
                            ? '#ce6868'
                            : '',
                      }),attrs:{"density":"compact","id":"turnroll_safety_censor_front","label":"","small":""}},[_c('div',{staticClass:"chip-content"},[_c('div',{staticClass:"chip-text"},[_vm._v(" "+_vm._s(_vm.turnroll_safety_censor_front === "경고" ? "경고" : "위험")+" ")]),_c('div',{staticClass:"label-divider"}),_c('span',{staticClass:"bold-chip",staticStyle:{"font-size":"12px"}},[_vm._v("전방센서")])])])],1),_c('div',{staticClass:"inline-block"},[_c('v-chip',{staticClass:"custom-chip",style:({
                        backgroundColor:
                          _vm.turnroll_safety_censor_back === '경고'
                            ? '#dab901'
                            : _vm.turnroll_safety_censor_back === '위험'
                            ? '#ce6868'
                            : '',
                      }),attrs:{"density":"compact","id":"turnroll_safety_censor_back","label":"","small":""}},[_c('div',{staticClass:"chip-content"},[_c('div',{staticClass:"chip-text"},[_vm._v(" "+_vm._s(_vm.turnroll_safety_censor_back === "경고" ? "경고" : "위험")+" ")]),_c('div',{staticClass:"label-divider"}),_c('span',{staticClass:"bold-chip",staticStyle:{"font-size":"12px"}},[_vm._v("후방센서")])])])],1)],1),_c('br'),_c('hr'),_c('br'),_c('div',{staticStyle:{"text-align":"center","display":"flex","-ms-flex-wrap":"wrap","flex-wrap":"wrap","-webkit-box-pack":"center","-ms-flex-pack":"center","width":"100%","justify-content":"left"}},[_c('v-chip',{class:[
                      'subtitle-chip',
                      _vm.$vuetify.theme.dark ? 'dark-mode' : '',
                    ],staticStyle:{"font-size":"12px"},attrs:{"pa-2":"","large":""}},[_c('span',{staticClass:"text-background"},[_vm._v("자동충전")])]),_c('div',{staticClass:"inline-block"},[_c('v-chip',{staticClass:"ml-2 mr-2 custom-chip",attrs:{"id":"charge","label":"","small":""}},[_c('div',{staticClass:"chip-content"},[_c('div',{staticClass:"chip-number",style:({
                            backgroundColor: _vm.charge ? '#dab901' : '',
                          })},[_vm._v(" "+_vm._s(_vm.charge ? "ON" : "OFF")+" ")]),_c('div',{staticClass:"label-divider"}),_c('span',{staticClass:"bold-chip"},[_vm._v("충전중")])])])],1),_c('div',{staticClass:"inline-block"},[_c('v-chip',{staticClass:"mx-1 custom-chip",attrs:{"id":"Pressure","label":"","small":""}},[_c('div',{staticClass:"chip-content"},[_c('div',{staticClass:"chip-number"},[_vm._v(_vm._s(_vm.Pressure)+"kg")]),_c('div',{staticClass:"label-divider"}),_c('span',{staticClass:"bold-chip"},[_vm._v("압력")])])])],1),_c('div',{staticClass:"inline-block"},[_c('v-chip',{staticClass:"mx-1 custom-chip",attrs:{"id":"stroke","label":"","small":""}},[_c('div',{staticClass:"chip-content"},[_c('div',{staticClass:"chip-number"},[_vm._v(_vm._s(_vm.stroke)+"mm")]),_c('div',{staticClass:"label-divider"}),_c('span',{staticClass:"bold-chip"},[_vm._v("스트로크")])])])],1)],1)]),_c('br'),_c('br')])])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }