<template>
  <v-container style="height: 100vh; overflow: hidden" pa-0 ma-0>
    <div
      class="propertydefault scrollable-card pa-0 ma-0 flex-direction:column;"
    >
      <v-row style="text-align: left" justify="center" dense>
        <v-col :cols="colMd" justify="center">
          <v-card>
            <div class="data-check-tab">
              <!-- Closed_Television component is always shown -->
              <CCTVLivestreaming :key="streamingTimeKey" @error="onStreamingError"/>
            </div>
          </v-card>

          <DataCheckCardFirst
            id="inst_components"
            title="도막측정 작업용 이동식협동로봇"
            :is-coating="isCoating"
            :is-polishing="!isCoating"
            :tx="inst_Tx"
            :ty="inst_Ty"
            :rz="inst_Rz"
            :height="inst_Lift"
            :j1="inst_joint_1"
            :j2="inst_joint_2"
            :j3="inst_joint_3"
            :j4="inst_joint_4"
            :j5="inst_joint_5"
            :j6="inst_joint_6"
            :s_front="inst_isWarningfront"
            :s_rear="inst_isWarningback"
            :b_sensor="inst_bumper"
            :emergency="inst_emergency_stop"
            :state="inst_Tool"
            :battery="inst_battery"
          >
            <v-card-text>
              <br />

              <br />
              <div style="display: flex; justify-content: center">
                <v-btn
                  class="inline-block-emergency"
                  color="error"
                  dark
                  large
                  style="
                    font-size: 24px; /* 버튼 텍스트 크기 증가 */
                    font-weight: bold;
                  "
                >
                  비상정지
                </v-btn>
              </div>

              <br />

              <br />

              <div
                style="
                  text-align: center;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                "
              >
                <v-chip
                  :class="[
                    'subtitle-chip-dif',
                    $vuetify.theme.dark ? 'dark-mode' : '',
                  ]"
                  style="font-size: 12px"
                  pa-2
                >
                  도막 측정값
                </v-chip>

                <div class="inline-block bold-chip">
                  <v-chip density="compact" class="ml-1" small label>{{
                    num
                  }}</v-chip>
                  <span style="font-size: 12px" class="chip-label"
                    >측정번호</span
                  >
                </div>

                <div class="inline-block bold-chip">
                  <v-chip density="compact" class="ml-6" small label>{{
                    value
                  }}</v-chip>
                  <span style="font-size: 12px" class="chip-label ml-6"
                    >측정값</span
                  >
                </div>
              </div>

              <v-simple-table
                class="ml-16 mx-auto centered-table"
                density="compact"
              >
                <thead>
                  <tr>
                    <th>측정번호</th>

                    <th>측정값</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in desserts" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.calories }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </DataCheckCardFirst>
        </v-col>

        <v-col :cols="colMd" ml-10 justify="center">
          <v-card class="mx-auto">
            <v-card
              class="mx-auto"
              :style="{
                border: '2px solid #d74b4b',
                maxWidth: '344px',
                height: '187px',
                backgroundColor: $vuetify.theme.dark
                  ? '#042b4d'
                  : '#c9d7e3' /* 다크 모드일 때 배경색 */,
                color: $vuetify.theme.dark
                  ? '#ffffff'
                  : '#000000' /* 라이트 모드일 때 텍스트 색상 */,
              }"
            >
              <v-card-text>
                <p class="small-text">
                  (안전센서) 상태에 따라 색상구분 표시<br />
                  OFF : 회색, 경고 : 황색, 위험 : 적색 <br />
                </p>
                <div class="top-padding">
                  <p class="small-text">
                    (범퍼, 비상정지, 툴)ON/OFF상태에 따라 색상표시<br />
                  </p>
                </div>
                <div class="top-padding">
                  <p class="small-text">
                    (도막측정값) 표와 같이 배열, 측정번호에 해당하는 셀에 값
                    표시
                  </p>
                </div>
              </v-card-text>
            </v-card>

            <DataCheckCardFirst
              id="polish_components"
              title=" 폴리싱 작업용 이동식협동로봇"
              :is-coating="isCoating"
              :is-polishing="!isCoating"
              :tx="polish_Tx"
              :ty="polish_Ty"
              :rz="polish_Rz"
              :height="polish_Lift"
              :j1="polish_joint_1"
              :j2="polish_joint_2"
              :j3="polish_joint_3"
              :j4="polish_joint_4"
              :j5="polish_joint_5"
              :j6="polish_joint_6"
              :s_front="polish_isWarningfront"
              :s_rear="polish_isWarningback"
              :b_sensor="polish_bumper"
              :emergency="polish_emergency_stop"
              :state="polish_Tool"
              :battery="polish_battery"
            >
              <br />
              <br />
              <br />
              <div>
                <div>
                  <v-chip
                    class="other-facilities bold-chip-title"
                    label
                    style="background-color: #e3b23c"
                  >
                    기타 설비
                  </v-chip>
                </div>
                <br />
                <br />
                <div>
                  <div
                    style="
                      text-align: center;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -ms-flex-wrap: wrap;
                      flex-wrap: wrap;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: left;
                      width: 100%;
                    "
                  >
                    <v-chip
                      :class="[
                        'subtitle-chip bold-chip-label',
                        $vuetify.theme.dark ? 'dark-mode' : '',
                      ]"
                      style="font-size: 12px"
                      pa-2
                      large
                    >
                      <span class="text-background">I/O 상태</span>
                    </v-chip>

                    <div class="inline-block">
                      <v-chip
                        density="compact"
                        class="custom-chip"
                        id="turnroll_bumper"
                        label
                        small
                        :style="{
                          backgroundColor:
                            turnroll_bumper === '경고'
                              ? '#dab901'
                              : turnroll_bumper === '위험'
                              ? '#ce6868'
                              : '',
                        }"
                      >
                        <div class="chip-content">
                          <div class="chip-text">
                            {{ turnroll_bumper === "경고" ? "경고" : "위험" }}
                          </div>
                          <div class="label-divider"></div>
                          <span style="font-size: 12px" class="bold-chip"
                            >범퍼센서</span
                          >
                        </div>
                      </v-chip>
                    </div>

                    <div class="inline-block">
                      <v-chip
                        density="compact"
                        class="custom-chip"
                        id="turnroll_emergency_stop"
                        label
                        small
                        :style="{
                          backgroundColor:
                            turnroll_emergency_stop === '경고'
                              ? '#dab901'
                              : turnroll_emergency_stop === '위험'
                              ? '#ce6868'
                              : '',
                        }"
                      >
                        <div class="chip-content">
                          <div class="chip-text">
                            {{
                              turnroll_emergency_stop === "경고"
                                ? "경고"
                                : "위험"
                            }}
                          </div>
                          <div class="label-divider"></div>
                          <span style="font-size: 12px" class="bold-chip"
                            >비상정지</span
                          >
                        </div>
                      </v-chip>
                    </div>

                    <div class="inline-block">
                      <v-chip
                        density="compact"
                        class="custom-chip"
                        id="turnroll_safety_censor_front"
                        label
                        small
                        :style="{
                          backgroundColor:
                            turnroll_safety_censor_front === '경고'
                              ? '#dab901'
                              : turnroll_safety_censor_front === '위험'
                              ? '#ce6868'
                              : '',
                        }"
                      >
                        <div class="chip-content">
                          <div class="chip-text">
                            {{
                              turnroll_safety_censor_front === "경고"
                                ? "경고"
                                : "위험"
                            }}
                          </div>
                          <div class="label-divider"></div>
                          <span style="font-size: 12px" class="bold-chip"
                            >전방센서</span
                          >
                        </div>
                      </v-chip>
                    </div>

                    <div class="inline-block">
                      <v-chip
                        density="compact"
                        class="custom-chip"
                        id="turnroll_safety_censor_back"
                        label
                        small
                        :style="{
                          backgroundColor:
                            turnroll_safety_censor_back === '경고'
                              ? '#dab901'
                              : turnroll_safety_censor_back === '위험'
                              ? '#ce6868'
                              : '',
                        }"
                      >
                        <div class="chip-content">
                          <div class="chip-text">
                            {{
                              turnroll_safety_censor_back === "경고"
                                ? "경고"
                                : "위험"
                            }}
                          </div>
                          <div class="label-divider"></div>
                          <span style="font-size: 12px" class="bold-chip"
                            >후방센서</span
                          >
                        </div>
                      </v-chip>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <div
                    style="
                      text-align: center;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -ms-flex-wrap: wrap;
                      flex-wrap: wrap;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      width: 100%;
                      justify-content: left;
                    "
                  >
                    <v-chip
                      :class="[
                        'subtitle-chip',
                        $vuetify.theme.dark ? 'dark-mode' : '',
                      ]"
                      style="font-size: 12px"
                      pa-2
                      large
                    >
                      <span class="text-background">자동충전</span>
                    </v-chip>

                    <div class="inline-block">
                      <v-chip
                        id="charge"
                        class="ml-2 mr-2 custom-chip"
                        label
                        small
                      >
                        <div class="chip-content">
                          <div
                            class="chip-number"
                            :style="{
                              backgroundColor: charge ? '#dab901' : '',
                            }"
                          >
                            {{ charge ? "ON" : "OFF" }}
                          </div>
                          <div class="label-divider"></div>
                          <span class="bold-chip">충전중</span>
                        </div>
                      </v-chip>
                    </div>

                    <div class="inline-block">
                      <v-chip
                        id="Pressure"
                        class="mx-1 custom-chip"
                        label
                        small
                      >
                        <div class="chip-content">
                          <div class="chip-number">{{ Pressure }}kg</div>
                          <div class="label-divider"></div>
                          <span class="bold-chip">압력</span>
                        </div>
                      </v-chip>
                    </div>

                    <div class="inline-block">
                      <v-chip id="stroke" class="mx-1 custom-chip" label small>
                        <div class="chip-content">
                          <div class="chip-number">{{ stroke }}mm</div>
                          <div class="label-divider"></div>
                          <span class="bold-chip">스트로크</span>
                        </div>
                      </v-chip>
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </DataCheckCardFirst>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "../event-bus.js";
import CCTVLivestreaming from "@/components/CCTVLivestreaming.vue";

import DataCheckCardFirst from "@/components/DataCheckCardFirst.vue";

export default {
  props: ["isListening", "leftSize", "rightSize"],
  name: "DataCheckTab",
  components: {
    CCTVLivestreaming,

    DataCheckCardFirst,
  },
  data() {
    const currentTime = (new Date()).getTime();
    return {
      streamingTimeKey: currentTime,
      isCoating: true, // 도막 측정 작업용 이동식 협동 로봇일 경우 true
      colMd: 6,
      dataList: [],
      errorFetchingData: false,
      inst_Tx: 0, // 초기 값
      inst_Ty: 0,
      inst_Rz: 0,
      inst_Lift: 0,
      polish_Tx: 0,
      polish_Ty: 0,
      polish_Rz: 0,
      polish_Lift: 0,
      inst_joint_1: 0,
      inst_joint_2: 0,
      inst_joint_3: 0,
      inst_joint_4: 0,
      inst_joint_5: 0,
      inst_joint_6: 0,
      polish_joint_1: 0,
      polish_joint_2: 0,
      polish_joint_3: 0,
      polish_joint_4: 0,
      polish_joint_5: 0,
      polish_joint_6: 0,
      inst_battery: 0,
      polish_battery: 0,
      inst_code: 0,
      polish_code: 0,
      Pressure: 0,
      stroke: 0,
      num: 0,
      value: 0,
      inst_isWarningfront: "", // 초기값은 경고
      inst_isWarningback: "",
      polish_safety_sensor: "",
      inst_bumper: "",
      inst_emergency_stop: "",
      inst_Tool: "",
      polish_bumper: "",
      polish_emergency_stop: "",
      polish_isWarningfront: "",
      polish_isWarningback: "",
      turnroll_emergency_stop: "",
      turnroll_bumper: "",
      turnroll_safety_censor_back: "",
      turnroll_safety_censor_front: "",
      charge: "",
      polish_Tool: "",
      incrementInterval: null, // 인터벌 ID를 저장
      isIncrementing: false, // incrementChip의 주기적 실행 여부
      lineHeight: "1.5", // 동적 줄 간격 값
      activeTab: "one", // 탭의 초기 값은 "one"
      items: ["light_cyan", "light_cyan_matte", "aluminium", "blue_glass"],

      desserts: [
        {
          name: "01",
          calories: 159,
        },
        {
          name: "02",
          calories: 237,
        },
        {
          name: "03",
          calories: 262,
        },
        {
          name: "04",
          calories: 305,
        },
        {
          name: "05",
          calories: 356,
        },
        {
          name: "06",
          calories: 375,
        },
        {
          name: "07",
          calories: 392,
        },
        {
          name: "08",
          calories: 408,
        },
        {
          name: "09",
          calories: 452,
        },
        {
          name: "10",
          calories: 518,
        },
      ],
    };
  },

  created() {
    this.colMd = this.$vuetify.breakpoint.mdAndUp ? 6 : 12;
    // this.updateColMd(this.rightSize);
    // 이벤트 리스너 등록
    // EventBus.$on("increment-chip", this.toggleIncrement);

    EventBus.$on("on-data", this.onRecvData);
  },
  beforeDestroy() {
    // 이벤트 리스너 해제
    // EventBus.$off("increment-chip", this.toggleIncrement);
    // EventBus.$off("on-data", this.onRecvData);

    // 컴포넌트가 파괴되면 인터벌도 정리
    if (this.incrementInterval) {
      clearInterval(this.incrementInterval);
    }
  },
  watch: {
    isListening() {
      console.debug(`isListening`, this.isListening);
    },
    rightSize(newValue) {
      this.updateColMd(newValue);
    },
  },
  methods: {
    onStreamingError() {
      // this.streamingTimeKey = (new Date()).getTime();
    },
    updateColMd(newSize) {
      this.colMd = newSize > 38 ? 6 : 12;
    },
    onRecvData(data) {
      if (!this.isListening) {
        console.debug("데이터 수신 OFF");
        return;
      }

      console.debug("데이터 수신 중..", data);

      this.item = data.message;
      switch (data.message.m_id) {
        case 1:
          this.inst_Tx = this.formatNumber(this.item.tx);
          this.inst_Ty = this.formatNumber(this.item.ty);
          this.inst_Rz = this.formatNumber(this.item.rz);
          this.inst_Lift = this.formatNumber(this.item.height);
          this.inst_joint_1 = this.formatNumber(this.item.j1);
          this.inst_joint_2 = this.formatNumber(this.item.j2);
          this.inst_joint_3 = this.formatNumber(this.item.j3);
          this.inst_joint_4 = this.formatNumber(this.item.j4);
          this.inst_joint_5 = this.formatNumber(this.item.j5);
          this.inst_joint_6 = this.formatNumber(this.item.j6);
          this.inst_battery = this.item.battery;
          this.inst_code = this.item.alam_c;
          this.inst_isWarningfront = this.item.s_front === 0 ? "경고" : "위험";
          this.inst_isWarningback = this.item.s_rear === 0 ? "경고" : "위험";
          this.inst_bumper = this.item.b_sensor === 0 ? "OFF" : "ON";
          this.inst_emergency_stop = this.item.emergency === 0 ? "OFF" : "ON";
          this.inst_Tool = this.item.state === 0 ? "OFF" : "ON";

          window.ObjectPostionRenew(
            data.message.m_id,
            this.item.tx,
            this.item.ty,
            this.item.rz,
            this.item.height,
            this.item.j1,
            this.item.j2,
            this.item.j3,
            this.item.j4,
            this.item.j5,
            this.item.j6
          );

          break;
        case 2:
          this.polish_Tx = this.formatNumber(this.item.tx);
          this.polish_Ty = this.formatNumber(this.item.ty);
          this.polish_Rz = this.formatNumber(this.item.rz);
          this.polish_Lift = this.formatNumber(this.item.height);
          this.polish_joint_1 = this.formatNumber(this.item.j1);
          this.polish_joint_2 = this.formatNumber(this.item.j2);
          this.polish_joint_3 = this.formatNumber(this.item.j3);
          this.polish_joint_4 = this.formatNumber(this.item.j4);
          this.polish_joint_5 = this.formatNumber(this.item.j5);
          this.polish_joint_6 = this.formatNumber(this.item.j6);
          this.polish_battery = this.item.battery;
          this.polish_code = this.item.alam_c;
          this.Polish_Tool = this.item.state === 0 ? "OFF" : "ON";
          this.polish_emergency_stop = this.item.emergency === 0 ? "OFF" : "ON";
          this.polish_isWarningfront =
            this.item.s_front === 0 ? "경고" : "위험";
          this.polish_isWarningback = this.item.s_rear === 0 ? "경고" : "위험";
          this.polish_bumper = this.item.b_sensor === 0 ? "OFF" : "ON";

          window.ObjectPostionRenew(
            data.message.m_id,
            this.item.tx,
            this.item.ty,
            this.item.rz,
            this.item.height,
            this.item.j1,
            this.item.j2,
            this.item.j3,
            this.item.j4,
            this.item.j5,
            this.item.j6
          );
          break;

        default:
          this.num = this.item.num;
          this.value = this.item.value;

          this.turnroll_bumper = this.item.tx === 0 ? "OFF" : "ON";
          this.turnroll_emergency_stop = this.item.ty === 0 ? "OFF" : "ON";
          this.turnroll_safety_censor_front =
            this.item.rz === 0 ? "경고" : "위험";
          this.turnroll_safety_censor_back =
            this.item.j1 === 0 ? "경고" : "위험";
          break;
      }
    },
    formatNumber(value) {
      const parts = value.toString().split(".");
      const integerPart = parts[0].slice(-5);
      const decimalPart = parts[1] ? parts[1].slice(0, 2) : "00";
      return `${integerPart}.${decimalPart}`;
    },
    updateTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
html,
body,
#app {
  height: 100%;
  margin: 0;
}
.subtitle-chip {
  background-color: #f0f0f0; /* 제목 색상 */
  color: #333; /* 텍스트 색상 */
}
.label-divider {
  width: 100%; /* 구분선 너비를 chip에 맞게 설정 */
  height: 1.3px; /* 구분선 높이 */
  background-color: #6d6c6c; /* 구분선 색상 */
  margin: 1px 0; /* 구분선과 텍스트 사이 간격 */
}
.bold-chip-label {
  font-weight: bold; /* 두꺼운 글씨 */
}

.custom-chip {
  width: 52px;
  margin-left: 5px;
  display: flex; /* Flexbox 활성화 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
  height: 45px !important; /* 높이 설정 */
  padding: 0; /* 패딩 제거 */
}

.chip-label {
  margin-top: 4px; /* 라벨과 칩 사이의 간격 */
}

.propertydefault {
  border-radius: 0 !important; /* 모서리를 둥글게 하지 않음 */
  width: 100%;
  height: 100%;
  overflow: hidden; /* 탭이 리사이즈될 때 내부 컨텐츠를 숨김 */
}

.custom-chip {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 30px; /* 원하는 너비로 설정 */
}
.custom-chip-big {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 150px; /* 원하는 너비로 설정 */
}
.custom-chip-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.custom-chip-middle {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* v-table의 스타일 조정 */
.v-data-table table {
  width: auto; /* 테이블 너비 자동으로 설정 */
  margin: 0 auto; /* 가운데 정렬 */
}
.other-facilities {
  text-align: left;
  margin-left: 37%;
}
.centered-table {
  margin: 0 auto; /* 테이블을 수평으로 가운데 정렬 */
}
.table-gap {
  padding-left: 50%;
  padding-right: 10px;
}
.top-padding {
  padding-top: 2px; /* 원하는 공백 크기를 설정하세요 */
}
.justify-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  padding-left: 0px;
  /* align-items: center; */
}
.vertical-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; /* 필요한 경우 이 부분을 조정하여 가운데 정렬 등으로 변경 */
}
.ga-2 > * {
  margin-right: 4px; /* 요소 간의 간격 조절 */
}
.inline-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 2px;
}
.inline-block-emergency {
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 2px;
}
.chip-label {
  margin-top: 4px;
}
.small-font {
  font-size: 14px;
}
.scrollable-card {
  max-height: 850px; /* 원하는 최대 높이 설정 */
  overflow-y: auto; /* 세로 스크롤 허용 */
}
.small-chip {
  font-size: 0.8em;
  display: inline-block;
}
.chip-text {
  font-weight: bold;
  font-size: 12px; /* 텍스트의 폰트 크기 설정 */
}
.small-chip-label {
  font-size: 0.9em; /* Smaller font size */
  margin-top: 2px; /* Adjust as needed */
}
.emergencystop {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.subtitle-chip {
  margin-left: 4px;
  height: 45px !important;
  width: 90px;
  display: flex; /* Flexbox를 적용하여 자식 요소 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
  padding: 6px 12px; /* 필요한 경우 패딩 조정 */
  border-radius: 14px !important; /* 모서리 둥글게 */
  background-color: #e3b23c !important; /* 배경색 설정 */
  font-weight: bold;
  font-size: 12px; /* 폰트 크기 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}

.subtitle-chip-dif {
  height: 30px !important;
  width: 90px;
  display: flex; /* Flexbox를 적용하여 자식 요소 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
  padding: 6px 12px; /* 필요한 경우 패딩 조정 */
  border-radius: 5px !important; /* 모서리 둥글게 */
  background-color: #a1a09e !important; /* 배경색 설정 */
  font-weight: bold;
  font-size: 12px; /* 폰트 크기 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}

.dark-mode.subtitle-chip {
  color: var(--v-theme-subtitleChip); /* 다크 모드 텍스트 색상 */
  border-color: var(--v-theme-subtitleChip); /* 다크 모드 테두리 색상 */
  border-width: 1px; /* 다크 모드에서도 동일한 테두리 두께 적용 */
  font-weight: bold;
}
.divider {
  border: 0;
  border-top: 1px solid #646262; /* 구분선 색상 및 두께 조정 */
  margin: 16px 0; /* 구분선 위아래 여백 조정 */
}
.small-text {
  font-size: 13.3px; /* 원하는 크기로 설정 */
  font-weight: 900;
}
.bold-chip {
  font-weight: bold;
  opacity: 0.7;
}
.bold-chip-title {
  font-weight: bold;
  opacity: 1;
}
.bold-chip-label {
  font-weight: bold;
}
.text-background {
  background-color: #e3b23c;
  padding: 6px 10px; /* 텍스트 주변에 약간의 패딩 추가 */
  border-radius: 4px; /* 필요에 따라 둥근 모서리 추가 */
  display: inline-block;
}
</style>
