function Storage() {

	const indexedDB = window.indexedDB;

	if ( indexedDB === undefined ) {

		console.warn( 'Storage: IndexedDB not available.' );
		return { init: function () {}, get: function () {}, set: function () {}, clear: function () {} };

	}

	const name = 'threejs-editor';
	const version = 1;

	let database;

	return {

		init: function ( callback ) {

			const request = indexedDB.open( name, version );
			request.onupgradeneeded = function ( event ) {

				const db = event.target.result;

				if ( db.objectStoreNames.contains( 'states' ) === false ) {

					db.createObjectStore( 'states' );

				}
                if (!db.objectStoreNames.contains('textData')) {
                    db.createObjectStore('textData', { keyPath: 'id' }); // Create new object store for text data
                }

			};

			request.onsuccess = function ( event ) {

				database = event.target.result;

				callback();

			};

			request.onerror = function ( event ) {

				console.error( 'IndexedDB', event );

			};


		},

		get: function ( callback ) {

			const transaction = database.transaction( [ 'states' ], 'readwrite' );
			const objectStore = transaction.objectStore( 'states' );
			const request = objectStore.get( 0 );
			request.onsuccess = function ( event ) {

				callback( event.target.result );

			};

		},

		set: function ( data ) {
			
			if(window.Viewmode !== 'Monitoring')
			{
				const start = performance.now();

				const transaction = database.transaction( [ 'states' ], 'readwrite' );
				const objectStore = transaction.objectStore( 'states' );
				const request = objectStore.put( data, 0 );
				request.onsuccess = function () {

					console.log( '[' + /\d\d:\d\d:\d\d/.exec( new Date() )[ 0 ] + ']', 'Saved state to IndexedDB. ' + ( performance.now() - start ).toFixed( 2 ) + 'ms' );

				};
			}

		},

		clear: function () {

			if ( database === undefined ) return;

			const transaction = database.transaction( [ 'states' ], 'readwrite' );
			const objectStore = transaction.objectStore( 'states' );
			const request = objectStore.clear();
			request.onsuccess = function () {

				console.log( '[' + /\d\d:\d\d:\d\d/.exec( new Date() )[ 0 ] + ']', 'Cleared IndexedDB.' );

			};

		},
        getText: function (callback) {
            const transaction = database.transaction(['textData'], 'readwrite');
            const objectStore = transaction.objectStore('textData');
            const request = objectStore.getAll();
            request.onsuccess = function (event) {
                const result = event.target.result;
                const data = {};
                result.forEach(item => data[item.id] = item);
                callback(data);
            };
        },
        setText: function (data) {
            const transaction = database.transaction(['textData'], 'readwrite');
            const objectStore = transaction.objectStore('textData');
            const request = objectStore.put(data);
            request.onsuccess = function () {
                console.log(`[${/\d\d:\d\d:\d\d/.exec(new Date())[0]}]`, `Saved text data to IndexedDB.`);
            };
        },
        clearText: function () {
            if (database === undefined) return;
            const transaction = database.transaction(['textData'], 'readwrite');
            const objectStore = transaction.objectStore('textData');
            const request = objectStore.clear();
            request.onsuccess = function () {
                console.log(`[${/\d\d:\d\d:\d\d/.exec(new Date())[0]}]`, 'Cleared text data from IndexedDB.');
            };
        }
    };
}

export { Storage };
