<template>
  <BarChartGenerator
    class="chart-container"
    :chart-options="chartOptions"
    :data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="chartPlugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar as BarChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    BarChartGenerator,
  },
  props: {
    chartPlugins: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({
        labels: ["a", "b"],
        values: [250, 150],
      }),
    },
  },
  data() {
    return {
      chartData: {
        labels: this.data.labels,
        datasets: [
          {
            label: "Dataset",
            backgroundColor: "#f87979",
            data: this.data.values,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.dataset.label + ": " + context.raw;
              },
            },
          },
        },
      },
    };
  },
  watch: {
    data: {
      handler(newData) {
        this.chartData.labels = newData.labels;
        this.chartData.datasets[0].data = newData.values;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    window.barChartGrapeinputdata = (labelsw, valuesw) => {
      this.$set(this, "chartData", {
        labels: labelsw,
        datasets: [
          {
            label: "Dataset",
            backgroundColor: "#f87979",
            data: valuesw,
          },
        ],
      });
    };
  },
};
</script>

<style scoped>
.chart-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
