<template>
  <LineChartGenerator
    class="chart-container"
    :chart-options="chartOptions"
    :data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Dataset",
            backgroundColor: "#f87979",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.dataset.label + ": " + context.raw;
              },
            },
          },
        },
      },
    };
  },
  watch: {
    data: {
      handler(newData) {
        this.chartData.labels = newData.labels;
        this.chartData.datasets[0].data = newData.values;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    window.lineChartGrapeinputdata = (labelsw, valuesw) => {
      this.chartData = {
        labels: labelsw,
        datasets: [
          {
            label: "Dataset",
            backgroundColor: "#f87979",
            data: valuesw,
          },
        ],
      };
    };
  },
};
</script>

<style scoped>
.chart-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
