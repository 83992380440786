
// eslint-disable-next-line no-unused-vars
import * as Commands from './commands/Commands.js';

class History {

	constructor(editor) {
		this.editor = editor;
		this.historyDisabled = false;
		this.config = editor.config;

		// signals
		const scope = this;

		this.editor.signals.startPlayer.add(function () {
			scope.historyDisabled = true;
		});

		this.editor.signals.stopPlayer.add(function () {
			scope.historyDisabled = false;
		});
	}

	execute(cmd, optionalName) {
		if (this.historyDisabled) {
			alert('Undo/Redo disabled while scene is playing.');
			return;
		}

		// 명령어 실행만 수행하고 내역은 저장하지 않음
		cmd.name = (optionalName !== undefined) ? optionalName : cmd.name;
		cmd.execute();
	}

	undo() {
		return;
		/*
		if (this.historyDisabled) {
			alert('Undo/Redo disabled while scene is playing.');
			return;
		}

		// Undo 기능을 비활성화
		alert('Undo is disabled.');
		*/
	}

	redo() {
		return;
		/*
		if (this.historyDisabled) {
			alert('Undo/Redo disabled while scene is playing.');
			return;
		}

		// Redo 기능을 비활성화
		alert('Redo is disabled.');
		*/
	}

	// eslint-disable-next-line no-unused-vars
	toJSON() {
		// 내역이 저장되지 않도록 빈 객체 반환
		return {};
	}

	// eslint-disable-next-line no-unused-vars
	fromJSON(json) {
		// 내역을 불러오지 않도록 메서드를 비워둠
	}

	clear() {
		// 내역을 비우는 기능을 제거
	}

	// eslint-disable-next-line no-unused-vars
	goToState(id) {
		if (this.historyDisabled) {
			alert('Undo/Redo disabled while scene is playing.');
			return;
		}

		// 상태 전환 기능을 비활성화
		alert('State transition is disabled.');
	}

	// eslint-disable-next-line no-unused-vars
	enableSerialization(id) {
		// 직렬화 기능을 없애기 위해 메서드를 비워둠
	}
}

export { History };

