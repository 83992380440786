// vuetify.js
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false, // 기본 모드를 false로 설정 (Light Mode)
    themes: {
      light: {
        primary: "#828282",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#FFFFFF", // 라이트 모드 배경 색상
        subtitleChip: "#505050", // 라이트 모드 subtitleChip 색상
      },
      dark: {
        primary: "#828282", // 다크 모드 primary 색상
        secondary: "#505c27",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#828282", // 다크 모드 배경 색상
        subtitleChip: "#FFFFFF", // 다크 모드 subtitleChip 색상 (밝은 색상)
      },
    },
  },
});
