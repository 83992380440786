function Config() {

	const name = 'threejs-editor';
	let storage
	if (window.Viewmode === 'Monitoring')
	{storage = {
		'language': 'ko',


		'autosave': false,

		'project/title': '',
		'project/editable': false,
		'project/vr': false,

		'project/renderer/antialias': true,
		'project/renderer/shadows': true,
		'project/renderer/shadowType': 1, // PCF
		'project/renderer/toneMapping': 0, // NoToneMapping
		'project/renderer/toneMappingExposure': 1,

		'settings/history': false,

		'settings/shortcuts/translate': 'w',
		'settings/shortcuts/rotate': 'e',
		'settings/shortcuts/scale': '',
		//'settings/shortcuts/scale': 'r',
		'settings/shortcuts/undo': 'z',
		'settings/shortcuts/focus': 'f',
		'settings/theme': 'Light',
	};

	}else{storage = {
		'language': 'ko',


		'autosave': true,

		'project/title': '',
		'project/editable': false,
		'project/vr': false,

		'project/renderer/antialias': true,
		'project/renderer/shadows': true,
		'project/renderer/shadowType': 1, // PCF
		'project/renderer/toneMapping': 0, // NoToneMapping
		'project/renderer/toneMappingExposure': 1,

		'settings/history': false,

		'settings/shortcuts/translate': 'w',
		'settings/shortcuts/rotate': 'e',
		'settings/shortcuts/scale': '',
		//'settings/shortcuts/scale': 'r',
		'settings/shortcuts/undo': 'z',
		'settings/shortcuts/focus': 'f',
		'settings/theme': 'Light',
	};

	}
	

	if ( window.localStorage[ name ] === undefined ) {

		window.localStorage[ name ] = JSON.stringify( storage );

	} else {

		const data = JSON.parse( window.localStorage[ name ] );

		for ( const key in data ) {

			storage[ key ] = data[ key ];

		}

	}

	return {

		getKey: function ( key ) {

			return storage[ key ];

		},

		setKey: function () { // key, value, key, value ...
			
			if(window.Viewmode !== 'Monitoring')
			{
				for ( let i = 0, l = arguments.length; i < l; i += 2 ) {

					storage[ arguments[ i ] ] = arguments[ i + 1 ];

				}

				window.localStorage[ name ] = JSON.stringify( storage );

				
				console.log( '[' + /\d\d:\d\d:\d\d/.exec( new Date() )[ 0 ] + ']', 'Saved config to LocalStorage.' );
			}

		},

		clear: function () {

			delete window.localStorage[ name ];

		}

	};

}

export { Config };
