import Vue from "vue";
import App from "./App.vue";
import router from "./router/mainRouter";
import vuetify from "./plugins/vuetify";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "@/plugins/websocket";
import "@/core/axios"; // Axios import 추가

Vue.use(VueMaterial);

// Vue 애플리케이션 생성 및 마운트
new Vue({
  render: (h) => h(App),
  vuetify,
  router,
}).$mount("#app");
