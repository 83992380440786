<template>
  <v-app :class="{ dark: isDarkMode }">
    <div id="app">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isDarkMode: false,
    };
  },
  watch: {
    isDarkMode(val) {
      this.$vuetify.theme.dark = val;
      document.body.style.backgroundColor = val
        ? this.$vuetify.theme.themes.dark.background
        : "";
    },
  },

  mounted() {
    document.body.style.backgroundColor = this.$vuetify.theme.dark
      ? this.$vuetify.theme.themes.dark.background
      : "";
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
}

#app > img {
  width: 100%;
  height: 100vh;
}

</style>
