<template id="inst_components">
  <v-card class="mx-auto pa-0 ma-0">
    <div style="text-align: center">
      <v-chip
        class="ma-2 bold-chip-title"
        label
        :style="{
          backgroundColor:
            title === '도막측정 작업용 이동식협동로봇' ? '#83bca9' : '#a79070',
        }"
      >
        {{ title }}
      </v-chip>
    </div>

    <br />

    <div
      class="chip-container"
      style="text-align: center; display: flex; flex-wrap: wrap; width: 100%"
    >
      <v-chip
        :class="[
          'subtitle-chip ',
          isCoating ? 'coating-color' : '',
          isPolishing ? 'polishing-color' : '',
          $vuetify.theme.dark ? 'dark-mode' : '',
        ]"
        style="font-size: 13px"
        large
      >
        <span class="text-background">모바일 좌표</span>
      </v-chip>

      <v-chip id="Tx_1" class="custom-chip" small label>
        <div class="chip-content">
          <div class="chip-number">{{ formatNumber(tx) }}</div>
          <div class="label-divider"></div>

          <span class="bold-chip">X좌표</span>
        </div>
      </v-chip>

      <v-chip id="Ty_1" class="custom-chip" small label>
        <div class="chip-content">
          <div class="chip-number">{{ formatNumber(ty) }}</div>
          <div class="label-divider"></div>
          <span class="bold-chip">Y좌표</span>
        </div>
      </v-chip>

      <v-chip id="Rz_1" class="custom-chip" small label>
        <div class="chip-content">
          <div class="chip-number">{{ formatNumber(rz) }}</div>
          <div class="label-divider"></div>
          <span class="bold-chip">Y좌표</span>
        </div>
      </v-chip>

      <v-chip id="Height_1" class="custom-chip" small label>
        <div class="chip-content">
          <div class="chip-number">{{ formatNumber(height) }}</div>
          <div class="label-divider"></div>
          <span class="bold-chip">리프트</span>
        </div>
      </v-chip>
    </div>

    <hr class="divider" />

    <div
      class="chip-container"
      style="text-align: center; display: flex; flex-wrap: wrap; width: 100%"
    >
      <v-chip
        :class="[
          'subtitle-chip ',
          isCoating ? 'coating-color' : '',
          isPolishing ? 'polishing-color' : '',
          $vuetify.theme.dark ? 'dark-mode' : '',
        ]"
        style="font-size: 13px"
        large
      >
        <span class="text-background"> I/O상태</span>
      </v-chip>

      <div class="inline-block">
        <v-chip
          density="compact"
          class="custom-chip"
          id="s_front"
          label
          small
          :style="{
            backgroundColor:
              s_front === '경고'
                ? '#dab901'
                : s_front === '위험'
                ? '#ce6868'
                : '',
          }"
        >
          <div class="chip-content">
            <div class="chip-text">
              {{ s_front === "경고" ? "경고" : "위험" }}
            </div>
            <div class="label-divider"></div>
            <span style="font-size: 12px" class="bold-chip">전방센서</span>
          </div>
        </v-chip>
      </div>

      <div class="inline-block">
        <v-chip
          density="compact"
          class="custom-chip"
          id="s_rear"
          label
          small
          :style="{
            backgroundColor:
              s_rear === '경고'
                ? '#dab901'
                : s_rear === '위험'
                ? '#ce6868'
                : '',
          }"
        >
          <div class="chip-content">
            <div class="chip-text">
              {{ s_front === "경고" ? "경고" : "위험" }}
            </div>
            <div class="label-divider"></div>
            <span style="font-size: 12px" class="bold-chip">후방센서</span>
          </div>
        </v-chip>
      </div>

      <div class="inline-block">
        <v-chip
          density="compact"
          class="custom-chip"
          id="b_sensor"
          label
          small
          :style="{ backgroundColor: b_sensor === 'ON' ? '#ce6868' : '' }"
        >
          <div class="chip-content">
            <div class="chip-text">{{ b_sensor === "ON" ? "ON" : "OFF" }}</div>
            <div class="label-divider"></div>
            <span style="font-size: 12px" class="bold-chip">범퍼센서</span>
          </div>
        </v-chip>
      </div>

      <div class="inline-block">
        <v-chip
          density="compact"
          class="custom-chip"
          id="emergency"
          label
          small
          :style="{ backgroundColor: emergency === 'ON' ? '#ce6868' : '' }"
        >
          <div class="chip-content">
            <div class="chip-text">{{ emergency === "ON" ? "ON" : "OFF" }}</div>
            <div class="label-divider"></div>
            <span style="font-size: 12px" class="bold-chip">비상정지</span>
          </div>
        </v-chip>
      </div>
    </div>

    <hr class="divider" />

    <div
      class="chip-container"
      style="text-align: center; display: flex; flex-wrap: wrap; width: 100%"
    >
      <v-chip
        :class="[
          'subtitle-chip square-chip ',
          isCoating ? 'coating-color' : '',
          isPolishing ? 'polishing-color' : '',
          $vuetify.theme.dark ? 'dark-mode' : '',
        ]"
        style="font-size: 13.5px; margin-right: 15px"
        large
      >
        <span class="text-background"
          >로봇 관절<br />
          각도
        </span>
      </v-chip>
      <div class="top-group">
        <div class="joint-group">
          <div class="inline-block">
            <v-chip id="inst_joint_1" class="custom-chip" small label>
              <div class="chip-content">
                <div class="chip-number">{{ formatNumber(j1) }}</div>
                <div class="label-divider"></div>
                <span class="bold-chip">1축</span>
              </div>
            </v-chip>
          </div>

          <div class="inline-block" style="margin-left: 15px">
            <v-chip id="inst_joint_2" class="custom-chip" small label>
              <div class="chip-content">
                <div class="chip-number">{{ formatNumber(j2) }}</div>
                <div class="label-divider"></div>
                <span class="bold-chip">2축</span>
              </div>
            </v-chip>
          </div>
          <div class="inline-block" style="margin-left: 15px">
            <v-chip id="inst_joint_3" class="custom-chip" small label>
              <div class="chip-content">
                <div class="chip-number">{{ formatNumber(j3) }}</div>
                <div class="label-divider"></div>
                <span class="bold-chip">3축</span>
              </div>
            </v-chip>
          </div>
        </div>

        <div class="joint-group" style="margin-top: 13px">
          <div class="inline-block">
            <v-chip id="inst_joint_4" class="custom-chip" small label>
              <div class="chip-content">
                <div class="chip-number">{{ formatNumber(j4) }}</div>
                <div class="label-divider"></div>
                <span class="bold-chip">4축</span>
              </div>
            </v-chip>
          </div>

          <div class="inline-block" style="margin-left: 15px">
            <v-chip id="inst_joint_5" class="custom-chip" small label>
              <div class="chip-content">
                <div class="chip-number">{{ formatNumber(j5) }}</div>
                <div class="label-divider"></div>
                <span class="bold-chip">5축</span>
              </div>
            </v-chip>
          </div>

          <div class="inline-block" style="margin-left: 15px">
            <v-chip id="inst_joint_6" class="custom-chip" small label>
              <div class="chip-content">
                <div class="chip-number">{{ formatNumber(j6) }}</div>
                <div class="label-divider"></div>
                <span class="bold-chip">6축</span>
              </div>
            </v-chip>
          </div>
        </div>
      </div>
    </div>
    <hr class="divider" />
    <div
      style="
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-right: 60px;
      "
    >
      <v-chip
        :class="[
          'subtitle-chip',
          isCoating ? 'coating-color' : '',
          isPolishing ? 'polishing-color' : '',
          $vuetify.theme.dark ? 'dark-mode' : '',
        ]"
        style="font-size: 13px; margin-right: 15px"
        pa-2
        large
      >
        <span class="text-background">기타</span>
      </v-chip>

      <div class="inline-block" style="margin-right: 22px">
        <v-chip id="state" class="custom-chip" small label>
          <div class="chip-content">
            <div
              class="chip-number"
              :style="{ backgroundColor: state === 'ON' ? '#ce6868' : '' }"
            >
              {{ state === "ON" ? "ON" : "OFF" }}
            </div>
            <div class="label-divider"></div>
            <span class="bold-chip">툴</span>
          </div>
        </v-chip>
      </div>

      <div class="inline-block">
        <v-chip id="battery" class="custom-chip-middle" small label>
          <div class="chip-content">
            <div class="chip-number">{{ battery }}%</div>
            <div class="label-divider"></div>
            <span class="bold-chip">배터리 잔량</span>
          </div>
        </v-chip>
      </div>
    </div>
    <hr class="divider" />
    <div
      style="
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-right: 75px;
      "
    >
      <v-chip
        :class="[
          'subtitle-chip ',
          isCoating ? 'coating-color' : '',
          isPolishing ? 'polishing-color' : '',
          $vuetify.theme.dark ? 'dark-mode' : '',
        ]"
        style="font-size: 13px; margin-right: 15px"
        large
      >
        <span class="text-background">알람 상태</span>
      </v-chip>

      <div class="inline-block" style="margin-right: 22px">
        <v-chip id="alarm_c" class="custom-chip" small label>
          <div class="chip-content">
            <div class="chip-number">{{ alarm_c }}</div>
            <div class="label-divider"></div>
            <span class="bold-chip">코드</span>
          </div>
        </v-chip>
      </div>

      <div class="inline-block">
        <v-chip
          class="custom-chip-middle"
          small
          label
          style="background-color: #dab901"
        >
          <div class="chip-content">
            <div class="chip-number">{{ alarm_d }}</div>
            <div class="label-divider"></div>
            <span class="bold-chip"> 알람 내용 </span>
          </div>
        </v-chip>
      </div>
    </div>

    <slot></slot>
  </v-card>
</template>

<script>
export default {
  //   data() {
  //     return {
  //       tx: 0,
  //   ty: 0,
  //   rz: 0,
  //   lift: 0,
  //   j1: 0,
  //   j2: 0,
  //   j3: 0,
  //   j4: 0,
  //   j5: 0,
  //   j6: 0,
  //   s_front: 0,
  //   s_rear: 0,
  //   b_sensor: 0,
  //   emergency: 0,
  // },

  props: {
    title: String, // title 값을 외부에서 전달받음
    isCoating: {
      type: Boolean,
      required: true,
      default: true,
    },
    isPolishing: {
      type: Boolean,
      required: true,
      default: false,
    },
    tx: {
      type: [Number, String],
      default: 0,
    },
    ty: {
      type: [Number, String],
      default: 0,
    },
    rz: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    j1: {
      type: [Number, String],
      default: 0,
    },
    j2: {
      type: [Number, String],
      default: 0,
    },
    j3: {
      type: [Number, String],
      default: 0,
    },
    j4: {
      type: [Number, String],
      default: 0,
    },
    j5: {
      type: [Number, String],
      default: 0,
    },
    j6: {
      type: [Number, String],
      default: 0,
    },
    s_front: {
      type: [Number, String],
      default: 0,
    },
    s_rear: {
      type: [Number, String],
      default: 0,
    },
    b_sensor: {
      type: [Number, String],
      default: 0,
    },
    emergency: {
      type: [Number, String],
      default: 0,
    },
    state: {
      type: String,
      default: "0",
    },
    battery: {
      type: [Number, String],
      default: "0",
    },
    alarm_c: {
      type: String,
      default: "0",
    },
    alarm_d: {
      type: String,
      default: "null",
    },
  },
  methods: {
    formatNumber(value) {
      const parts = value.toString().split(".");
      const integerPart = parts[0].slice(-5);
      const decimalPart = parts[1] ? parts[1].slice(0, 2) : "00";
      return `${integerPart}.${decimalPart}`;
    },
  },
};
</script>

<style scoped>
.custom-chip {
  width: 52px;
  margin-left: 5px;
  display: flex; /* Flexbox 활성화 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
  height: 45px !important; /* 높이 설정 */
  padding: 0; /* 패딩 제거 */
}
.chip-content {
  display: flex; /* Flexbox 활성화 */
  flex-direction: column; /* 상하 정렬 */
  align-items: center; /* 수평 중앙 정렬 */
  justify-content: center; /* 수직 중앙 정렬 */
}
.chip-text {
  font-weight: bold;
  font-size: 12px; /* 텍스트의 폰트 크기 설정 */
}
.chip-number {
  font-size: 14px; /* 숫자의 폰트 크기 설정 */
}
.custom-chip-big {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 150px; /* 원하는 너비로 설정 */
}

.custom-chip-middle {
  height: auto !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 128px; /* 원하는 너비로 설정 */
}
.inline-block {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 2px;
}

.small-font {
  font-size: 14px;
}

.small-chip {
  font-size: 0.8em;
  display: inline-block;
}

.small-chip-label {
  font-size: 0.9em; /* Smaller font size */
  margin-top: 2px; /* Adjust as needed */
}

.subtitle-chip {
  height: 45px !important;
  width: 90px;
  display: flex; /* Flexbox를 적용하여 자식 요소 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
  padding: 6px 12px; /* 필요한 경우 패딩 조정 */
  border-radius: 14px !important; /* 모서리 둥글게 */
  margin-left: 4px;
  font-weight: bold;
  font-size: 12px; /* 폰트 크기 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}

.dark-mode.subtitle-chip {
  color: var(--v-theme-subtitleChip); /* 다크 모드 텍스트 색상 */
  border-color: var(--v-theme-subtitleChip); /* 다크 모드 테두리 색상 */
  border-width: 1px; /* 다크 모드에서도 동일한 테두리 두께 적용 */
}
.divider {
  border: 0;
  border-top: 1px solid #bfbfbe; /* 구분선 색상 및 두께 조정 */
  margin: 16px 0; /* 구분선 위아래 여백 조정 */
}
.label-divider {
  width: 100%; /* 구분선 너비를 chip에 맞게 설정 */
  height: 1.3px; /* 구분선 높이 */
  background-color: #6d6c6c; /* 구분선 색상 */
  margin: 1px 0; /* 구분선과 텍스트 사이 간격 */
}
.joint-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .joint-group {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.custom-chip-size {
  width: 100px !important; /* 원하는 너비로 설정 */
  height: 100px !important; /* 원하는 높이로 설정 */
  font-size: 12px; /* 폰트 크기 조정 */
  padding: 6px 12px; /* 패딩 조정 */
  line-height: 1; /* 줄 높이 조정 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; /* 좌측 정렬 */
  text-align: center;
  font-weight: bold;
}

.dark-mode.subtitle-chip {
  color: var(--v-theme-subtitleChip); /* 다크 모드 텍스트 색상 */
  border-color: var(--v-theme-subtitleChip); /* 다크 모드 테두리 색상 */
  border-width: 1px; /* 다크 모드에서도 동일한 테두리 두께 적용 */
}

.bold-chip-title {
  font-weight: bold;
}

.bold-chip {
  font-weight: bold;
  opacity: 0.7;
}
.no-padding {
  padding: 0 !important; /* 패딩 제거 */
  margin: 0 !important; /* 마진 제거 */
}

.no-space {
  gap: 0px; /* v-row에서 열 간의 간격 제거 */
}
.square-chip {
  height: 110px !important;
}
.coating-color {
  background-color: #83bca9 !important; /* 도막 측정 색상 */
}

.polishing-color {
  background-color: #a79070 !important; /* 폴리싱 색상 */
}
</style>
