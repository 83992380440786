<template>
  <div class="d-flex fill-height">
    <div class="left" ref="leftSide">
      <slot name="left"></slot>
    </div>
    <div id="dragMe" class="resizer" ref="resizer"></div>
    <div class="right" ref="rightSide">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initialLeftWidth: 990, // 초기 left 너비 (px)
      minRightWidth: 260, // right의 최소 너비 (px)
    };
  },
  mounted() {
    const resizer = this.$refs.resizer;
    const leftSide = this.$refs.leftSide;
    const rightSide = this.$refs.rightSide;

    let x = 0;
    let leftWidth = 0;

    const updateWidths = () => {
      const parentWidth = resizer.parentNode.getBoundingClientRect().width;
      const minRightWidthPercentage = (this.minRightWidth * 100) / parentWidth;
      const initialLeftWidthPercentage =
        (this.initialLeftWidth * 100) / parentWidth;

      // Make sure initialLeftWidth doesn't exceed the maximum allowed width
      const newLeftWidth = Math.min(
        initialLeftWidthPercentage,
        100 - minRightWidthPercentage
      );
      leftSide.style.width = `${newLeftWidth}%`;
      rightSide.style.width = `${100 - newLeftWidth}%`;

      this.$emit("resize", {
        left: newLeftWidth,
        right: 100 - newLeftWidth,
      });
    };

    const mouseDownHandler = (e) => {
      x = e.clientX;
      leftWidth = leftSide.getBoundingClientRect().width;

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      //마우스를 누른 상태에서 스타일 변경
      resizer.classList.add("resizer-active");
    };

    window.VuetResizerRefresh = function (value) {
      if (value) {
        leftSide.style.width = 100 + `%`;
        rightSide.style.width = 0 + `%`;
      } else {
        let parentWidth = resizer.parentNode.getBoundingClientRect().width;
        leftSide.style.width = parentWidth - 500 + `px`;
        rightSide.style.width = 500 + `px`;
      }
      window.threeViewportResizer();
    };
    const mouseMoveHandler = (e) => {
      const dx = e.clientX - x;
      const parentWidth = resizer.parentNode.getBoundingClientRect().width;
      const minRightWidth = this.minRightWidth;

      document.body.style.cursor = "col-resize";
      leftSide.style.userSelect = "none";
      leftSide.style.pointerEvents = "none";
      rightSide.style.userSelect = "none";
      rightSide.style.pointerEvents = "none";

      const newLeftWidth = leftWidth + dx;
      const newRightWidth = parentWidth - newLeftWidth;

      if (newRightWidth >= minRightWidth) {
        leftSide.style.width = `${(newLeftWidth * 100) / parentWidth}%`;
        rightSide.style.width = `${(newRightWidth * 100) / parentWidth}%`;
      }

      window.threeViewportResizer();
      this.$emit("resize", {
        left: (newLeftWidth * 100) / parentWidth,
        right: (newRightWidth * 100) / parentWidth,
      });
    };

    const mouseUpHandler = () => {
      resizer.style.removeProperty("cursor");
      document.body.style.removeProperty("cursor");

      leftSide.style.removeProperty("user-select");
      leftSide.style.removeProperty("pointer-events");
      rightSide.style.removeProperty("user-select");
      rightSide.style.removeProperty("pointer-events");

      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);

      // 마우스를 떼면 스타일 복구
      resizer.classList.remove("resizer-active");
    };

    resizer.addEventListener("mousedown", mouseDownHandler);

    window.addEventListener("resize", updateWidths);

    // 초기 너비 설정
    updateWidths();

    // Clean up the event listeners when the component is destroyed
    this.$once("hook:beforeDestroy", () => {
      resizer.removeEventListener("mousedown", mouseDownHandler);
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      window.removeEventListener("resize", updateWidths);
    });
  },
};
</script>

<style scoped>
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fill-height {
  height: 100%;
}

.left {
  overflow: hidden;
}

.resizer {
  border-radius: 0 !important;
  background-color: #a4a5a1;
  cursor: ew-resize;
  height: 100%;
  width: 2px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: width 0.2s, background-color 0.2s;
  -o-transition: width 0.2s, background-color 0.2s;
  transition: width 0.2s, background-color 0.2s;
  position: relative; /* 가상 요소를 사용할 수 있도록 position을 설정 */
}
.resizer:hover,
.resizer-active {
  background-color: #ee750b; /* 마우스를 올리면 색상을 주황색으로 변경 */
  width: 4px; /* 마우스를 올리면 너비를 4px로 변경 */
}
.resizer:before {
  content: "";
  position: absolute;
  top: 0;
  left: -6px; /* 좌우로 각각 5px씩 더 넓혀줌 */
  right: -6px;
  bottom: 0;
  background-color: transparent;
}
.right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  /*min-width: 20px;*/ /* right의 최소 너비 설정 */
}
</style>
