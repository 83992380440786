<template>
  <div class="main-content">
    <v-card class="fill-height">
      <component :is="currentComponent"></component>
    </v-card>
    <GraphView v-if="selectedTab === 'graph'" />
  </div>
</template>

<script>
import ThreeDView from "./ThreeDView.vue";
import GraphView from "./GraphChartTab.vue";
import { EventBus } from "../event-bus.js";

export default {
  components: {
    ThreeDView,
    GraphView,
  },
  props: {
    selectedTab: String,
    isPropertyPanelVisible: Boolean,
  },
  data() {
    return {
      currentComponent: ThreeDView,
    };
  },
  created() {
    EventBus.$on("change-tab", this.handleTabChange);
  },
  destroyed() {
    EventBus.$off("change-tab", this.handleTabChange);
  },
  methods: {
    handleTabChange(tabName) {
      switch (tabName) {
        case "3d":
          this.currentComponent = ThreeDView;
          break;
        case "graph":
          this.currentComponent = GraphView;
          break;
        // 다른 탭에 대한 처리 추가
      }
    },
    showOpenButton() {
      this.$refs.openButton.style.display = "block";
    },
    togglePropertyPanel() {
      this.$emit("update:isPropertyPanelVisible", !this.isPropertyPanelVisible);
    },
  },
};
</script>

<style>
.fill-height {
  height: 100%;
}

.main-content {
  /* flex: 1; */
  height: 100%;
  padding: 0;
  background: white;

  overflow: hidden;
}

.v-card.fill-height {
  height: 100%;
  border-radius: 0 !important; /* 모서리를 둥글게 하지 않음 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.graph-container {
  height: 100%;
  border-radius: 0 !important; /* 모서리를 둥글게 하지 않음 */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: center; 가운데 정렬로 변경 */
  /* align-items: center; */
  /* padding: 10px; */
}
</style>
