<template>
  <div class="livestream">
    <img
      id="livestream"
      crossorigin="anonymous"
      ref="livestream"
      alt="실시간 CCTV 모니터링" 
      class="streaming-image"
      :class="{ nostream: srcNotLoaded }"
      :src="currentSrc"
      @dblclick.stop="toggleFullscreen"
    />

    <button id="fullscreen-button" class="fullscreen-button" @click.prevent.stop="toggleFullscreen">
      <span v-if="isFullscreen">✖</span>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize">
        <path d="M8 3H5a2 2 0 0 0-2 2v3" />
        <path d="M16 3h3a2 2 0 0 1 2 2v3" />
        <path d="M8 21H5a2 2 0 0 1-2-2v-3" />
        <path d="M16 21h3a2 2 0 0 0 2-2v-3" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CCTVLivestreaming',
  data() {
    return {
      imgKey: 0,
      retryCount: 0,
      retryTimer: null,
      initTimer: 0,
      srcNotLoaded: true,
      currentSrc: '',
      isFullscreen: false,
      checkIntervalId: 0,
    };
  },
  computed: {
    retryDelay() {
      return Math.min(this.retryCount * 100, 60 * 1000);
    }
  },
  methods: {
    updateImageSrc() {
      const { protocol, host, hostname } = window.location;
      const isProduction = process.env.NODE_ENV === 'production';

      let baseURL = !isProduction && /^localhost:(\d{4})$/g.test(host) 
        ? `${protocol}//${hostname}` 
        : `${protocol}//${host}`
        ;

      this.currentSrc = `${baseURL}/livestream`;
    },
    toggleFullscreen() {
      const livestreamEl = this.$refs.livestream;
      
      if (document.fullscreenElement) {
        document.exitFullscreen();
        return;
      }

      livestreamEl.requestFullscreen()
        .catch(err => {
          console.debug(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    },
    onFullscreenChanged() {
      this.isFullscreen = document.fullscreenElement ? true : false;
    },
    startStreaming() {
      const $loading = document.getElementById('loading-screen');
      const $threejs = document.querySelector('canvas[data-engine^="three.js"]');

      if (!$threejs || ($threejs && $loading)) {
        console.debug(`loading...`)
        clearTimeout(this.initTimer);
        this.initTimer = setTimeout(() => {
          this.startStreaming();
        }, 5 * 1000);
        return;
      }

      this.updateImageSrc();

      document.addEventListener('fullscreenchange', this.onFullscreenChanged);
      
      this.retryTimer = this.retryTimer || setInterval(() => {
        this.checkStreaming();
      }, 5 * 1000);
    },
    checkStreaming() {
      const $livestream = document.getElementById('livestream');
      const isSrcBroken = $livestream && $livestream.naturalWidth <= 0;
      this.srcNotLoaded = !$livestream || isSrcBroken;

      console.debug(`isSrcBroken: `, isSrcBroken);

      if (isSrcBroken) {
        this.$emit('error');
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.startStreaming();
    });
  },
  beforeDestroy() {
    this.currentSrc = '';
    console.debug(`livestreaming destroy !`)
    clearInterval(this.retryTimer);
    document.removeEventListener('fullscreenchange', this.onFullscreenChanged);
  },
};
</script>

<style scoped>
.livestream {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: #000;
  border: none;
  box-shadow: 1px 1px 1px 1px #444;
  border-radius: 2px;
  overflow: hidden;
}

.streaming-image {
  width: 100%;
  /* display: block; */
  height: auto;
  max-height: 480px;
  object-fit: contain;
}

.streaming-image.nostream {
  width: 100%;
  height: auto;
  min-height: 240px;
  max-height: 480px;
  object-fit: contain;
}

#fullscreen-button {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 .5rem .5rem 0;
  border: none;
  padding: .25rem;
  color: white;
  cursor: pointer;
  line-height: 1;
  box-sizing: border-box;
  height: 2rem;
  background-color: transparent !important;
  background: transparent !important;
  transition: transform 0.2s ease-in-out;
}

#fullscreen-button:hover {
  transform: scale(1.25);
}

#fullscreen-button:active {
  transform: scale(1.25);
}
</style>