import { UIPanel, UIRow } from './libs/ui.js';

function MenubarRemote( editor ) {

	const strings = editor.strings;

	const container = new UIPanel();
	container.setClass( 'menu' );

	const title = new UIPanel();
	title.setClass( 'title' );
	title.setTextContent( strings.getKey( 'menubar/Remote' ) );
	container.add( title );

	const options = new UIPanel();
	options.setClass( 'options' );
	container.add( options );


	let option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/Remote/Remote_support' ) );
	option.onClick( function () {

		window.open( 'https://939.co.kr/stni001/', '_blank' );

	} );
	options.add( option );

	return container;

}

export { MenubarRemote };
