import Vue from "vue";
import VueNativeSock from "vue-native-websocket";

try {
  const { protocol, hostname } = window.location;
  const websocketHost = `${protocol}//${hostname}/ws`;
  
  Vue.use(VueNativeSock, websocketHost, {
    format: "json",
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
    logger: false,
    debug: false
  });
} catch (error) {
  console.log(error);
}
