/**====================================================
 * Backend Dev Server Proxy Pass Config
 * ---------------------------------------------------- 
 * dev: localhost:8080 => localhost/api (nginx -> bakcend)
 ======================================================*/

import axios from 'axios';

const { protocol, host, hostname } = window.location;
const isProduction = process.env.NODE_ENV === 'production';
const baseURL = !isProduction && /^localhost:(\d{4})$/g.test(host) 
    ? `${protocol}//${hostname}` 
    : `${protocol}//${host}`
    ;
axios.defaults.baseURL = baseURL;
export default axios;