import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "@/views/MainLayout.vue";
import ThreeDView from "@/views/ThreeDView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MainLayout,
  },
  {
    path: "/ThreeDView",
    component: ThreeDView,
  },
];

const mainRouter = new VueRouter({
  mode: "history",
  routes,
});

export default mainRouter;
