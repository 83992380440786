<template>
  <div class="property-panel">
    <div class="tab-bar">
      <v-card>
        <v-tabs v-model="tab">
          <v-btn icon class="toggleicon" @click="closePropertyPanel">
            <v-icon>{{
              isPropertyPanelVisible ? "mdi-chevron-right" : "mdi-chevron-left"
            }}</v-icon>
          </v-btn>

          <v-tab @click="showTab(1)">데이터확인</v-tab>
          <!-- <v-tab @click="showTab(2)">그래프</v-tab> -->
          <v-tab class="tab" @click="showTab(0)">속성</v-tab>
          <!-- <v-btn class="connect_btn" @click.prevent.stop="incrementChipLabel">{{
            isListening ? "OFF" : "ON"
          }}</v-btn> -->
          <v-switch
            v-model="darkMode"
            :label="darkMode ? 'Light Mode' : 'Dark Mode'"
            hide-details
            inset
            class="tab-switch"
          ></v-switch>
        </v-tabs>
      </v-card>
    </div>

    <!-- 각 탭에 따라 다른 내용을 표시 -->
    <v-card v-if="showPropertyTab === 1" class="card-control">
      <DataCheckTab
        :is-listening="isListening"
        :left-size="leftSize"
        :right-size="rightSize"
      />
    </v-card>
    <!-- <v-card v-else-if="showPropertyTab === 0" class="card-control">
      <PropertyTabs />
    </v-card> -->
    <v-card v-else-if="showPropertyTab === 2" class="card-control">
      <GraphChartTab />
    </v-card>
  </div>
</template>

<script>
import { EventBus } from "../event-bus.js";
import GraphChartTab from "@/views/GraphChartTab.vue";
import DataCheckTab from "@/views/DataCheckTab";
import vuetify from "../plugins/vuetify"; // Vuetify 인스턴스를 가져옴

export default {
  props: ["leftSize", "rightSize"],
  components: {
    DataCheckTab,
    GraphChartTab,
  },
  data() {
    return {
      tab: 0, // 탭 초기값 추가
      headers: [
        { text: "Month", value: "month" },
        { text: "Data", value: "data" },
      ],
      desserts: [],
      showPropertyTab: 1, // 초기로 데이터확인 탭을 보이도록 설정
      isPropertyPanelVisible: true, // 속성 패널의 가시성 상태
      darkMode: vuetify.framework.theme.dark, // 현재 모드를 저장할 변수를 추가
      eventcounter: 0, // 초기로 데이터확인 탭을 보이도록 설정
      isListening: false,
    };
  },
  watch: {
    darkMode(newVal) {
      // 모드가 변경될 때마다 로컬 스토리지에 저장
      localStorage.setItem("darkMode", JSON.stringify(newVal));
      // Vuetify 테마를 업데이트합니다.
      vuetify.framework.theme.dark = newVal;

      if (newVal) {
        window.threeThemeChange("Dark");
      } else {
        window.threeThemeChange("Light");
      }
    },
    // rightSize(newSize) {
    //   // 1. right width 960보다 커지거나 작아진 이벤트를 감지
    //   if (newSize < 960) {
    //     EventBus.$emit("right-size-change", "small");
    //   } else {
    //     EventBus.$emit("right-size-change", "large");
    //   }

    //   // 2. DataCheckTab에 이벤트 전달
    //   this.$refs.dataCheckTab.updateColMd(newSize);

    //   // 3. cols 사이즈 조정
    //   this.updateColMd(newSize);
    // },
  },
  created() {
    // 캐시된 모드가 있는지 확인하고, 있으면 적용
    const cachedMode = localStorage.getItem("darkMode");
    if (cachedMode !== null) {
      this.darkMode = JSON.parse(cachedMode);
    }
    // incrementChipLabel을 created 라이프사이클 훅에서 호출
    this.incrementChipLabel();
  },
  mounted() {
    EventBus.$on("data-change", this.updateDesserts);
  },
  beforeDestroy() {
    EventBus.$off("data-change", this.updateDesserts);
  },
  methods: {
    showTab(tabIndex) {
      this.showPropertyTab = tabIndex;
    },
    updateDesserts(newChartData) {
      console.log("Received new data:", newChartData);
      this.desserts = newChartData.labels.map((label, index) => ({
        month: label,
        data: newChartData.datasets[0].data[index],
      }));
    },
    closePropertyPanel() {
      window.VuetResizerRefresh(true);
      this.$emit("close-propertypanel");
      EventBus.$emit("show-open-button");
    },
    togglePropertyPanel() {
      this.isPropertyPanelVisible = !this.isPropertyPanelVisible;
    },
    // incrementChipLabel() {
    //   if (this.eventcounter == 0) {
    //     console.debug("ON 상태로 전환");
    //     EventBus.$emit("increment-chip", "ON");
    //     this.eventcounter = 1;
    //   } else {
    //     console.debug("OFF 상태로 전환");
    //     EventBus.$emit("increment-chip", "OFF");
    //     this.eventcounter = 0;
    //   }
    // },
    incrementChipLabel() {
      this.isListening = !this.isListening;
    },
  },
};
</script>

<style>
.app {
  border-radius: 0 !important; /* 모서리를 둥글게 하지 않음 */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; /* 추가 */
}
.property-panel {
  border-radius: 0 !important; /* 모서리를 둥글게 하지 않음 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  overflow: hidden;
  margin: 0;
  padding: 0; /* 추가 */
}
.toggleicon {
  position: inherit;
  top: 12%;
  margin-left: 16px; /* 스위치와 탭 간의 간격을 추가 */
}
.tab-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  margin: 0;
  padding: 0; /* 추가 */
}

.flex-grow-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto; /* Ensures content is scrollable if it overflows */
  margin: 0;
  padding: 0; /* 추가 */
}
.tab-switch {
  margin-top: 10px; /* 상단으로 간격을 추가 */
  /*margin-left: 300px;*/
  margin-left: 20px;
}
.tab {
  display: none;
}
.card-control {
  border-radius: 0 !important;
  /* height: 100vh; */
}
.connect_btn {
  margin-top: 6px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
